module.exports = /*@ngInject*/ function ($scope, $q, $window, $timeout, $location, FilterProvider, RealEstateSpinner) {
    let projectsUrl;
    let timer;
    let initialFilter = {};
    let inited = false;

    $scope.amount = null;
    $scope.types = [];
    $scope.types2 = [];
    $scope.location = [];
    $scope.locations = [];
    $scope.locationText = [];
    $scope.kategoria = [];
    $scope.states = [];
    $scope.countries = [];
    $scope.regions = [];
    $scope.cities = [];
    $scope.categories = [];
    $scope.loadingFilter = true;
    $scope.spinner = RealEstateSpinner.proccess;
    $scope.filterVersion = null;
    $scope.filter = {
        balcon: 0,
        breadcrumbs: false,
        category: [],
        city: [],
        coordinates: [],
        coordinateType: '',
        country: '',
        fullText: '',
        kategoria: [],
        locationText: [],
        new: 0,
        priceFrom: '',
        priceTo: '',
        region: [],
        sizeFrom: '',
        sizeTo: '',
        specie: '',
        state: '',
        type: '',
        types: [],
        virtual: 0,
    };

    $scope.init = init;
    $scope.filterProjects = filterProjects;
    $scope.toggleCategory = toggleCategory;
    $scope.toggleRegion = toggleRegion;
    $scope.toggleCity = toggleCity;

    $scope.$watch('filter', function (val, oldVal) {
        if (val == oldVal) {
            loadAmount();
        } else {
            $timeout.cancel(timer);
            timer = $timeout(function () {
                loadAmount();
            }, 200);
        }
    }, true);

    $scope.$watch('location', function (location, oldVal) {
        if (location !== oldVal) {
            const isCountry = $scope.countries.some(function (country) {
                return country.id === location[0].id;
            });

            $scope.regions = [];
            $scope.filter.region = [];
            $scope.filter.city = [];
            $scope.cities = [];

            if (isCountry) {
                loadRegions(location[0].id);
                $scope.filter.country = location[0].id;
                $scope.filter.state = '';

            } else {
                $scope.filter.state = location[0].id;
                $scope.filter.country = '';
            }
        }
    }, true);

    $scope.$watch('type', function (type, oldVal) {
        if (type !== oldVal) {
            if(Array.isArray(type) && type.length>0){
                $scope.filter.type = type[0].label;
            }
        }
    });

    $scope.$watch('specie', function (specie, oldVal) {  
        if (specie !== oldVal) {       
            if(Array.isArray(specie) && specie.length>0){
                $scope.filter.specie = specie[0].label;  
            }
        }
    });

    $scope.$watch('filter.region', function (regionsId, oldVal) {
        if (regionsId !== oldVal) {
            loadCities(regionsId);
            $scope.filter.city = [];
        }
    });

    $scope.$watch('filter.specie', function (specieId, oldVal) {
        if (specieId !== oldVal) {
            loadCategories(specieId);
            $scope.filter.category = [];
        }
    });

    $scope.$watch('filter.kategoria', function (newVal, oldVal) {
        if (oldVal != newVal && $scope.filterVersion == 'B' && newVal.length == 0) {
            $scope.filter.specie = '';
            $scope.filter.category = [];
        }
    });

    $scope.$watch('filter.locationText', function (newVal, oldVal) {
        if (oldVal != newVal && $scope.filterVersion == 'B' && newVal.length == 0) {
            $scope.regions = [];
            $scope.filter.region = [];
            $scope.filter.city = [];
            $scope.cities = [];
            $scope.filter.country = '';
            $scope.filter.state = '';
        }
    });

    function init(p_projectsUrl, filterVersion = null) {
        $scope.filterVersion = filterVersion;
        projectsUrl = p_projectsUrl;

        let geoJSON = {};
        if ($location.search()['geoJSON[type]']) {
            geoJSON['type'] = $location.search()['geoJSON[type]'];
        }
        if ($location.search()['geoJSON[box][0][]']) {
            geoJSON['box'] = [
                $location.search()['geoJSON[box][0][]'],
                $location.search()['geoJSON[box][1][]']
            ];
        }

        const data = {
            balcon: $location.search().balcon ? true : 0,
            breadcrumbs: $location.search().breadcrumbs ? true : false,
            city: $location.search()['city[]'] ? $location.search()['city[]'] : [],
            coordinates: [
                $location.search()['coordinates[0][]'] ? $location.search()['coordinates[0][]'] : [],
                $location.search()['coordinates[1][]'] ? $location.search()['coordinates[1][]'] : [],
            ],
            coordinateType: $location.search().coordinateType ? $location.search().coordinateType : '',
            country: $location.search().country ? $location.search().country : '',
            fullText: $location.search().fullText ? $location.search().fullText : '',
            kategoria: $location.search()['kategoria[]'] ? $location.search()['kategoria[]'] : null,
            locationText: $location.search()['locationText[]'] ? $location.search()['locationText[]'] : null,
            geoJSON: geoJSON,
            new: $location.search().new ? true : 0,
            priceFrom: $location.search().priceFrom ? $location.search().priceFrom : '',
            priceTo: $location.search().priceTo ? $location.search().priceTo : '',
            region: $location.search()['region[]'] ? $location.search()['region[]'] : [],
            sizeFrom: $location.search().sizeFrom ? $location.search().sizeFrom : '',
            sizeTo: $location.search().sizeTo ? $location.search().sizeTo : '',
            specie: $location.search().specie ? $location.search().specie : '',
            state: $location.search().state ? $location.search().state : '',
            type: $location.search().type ? $location.search().type : '',
            types: $location.search()['types[]'] ? $location.search()['types[]'] : [],
            virtual: $location.search().virtual ? true : 0,

        }

        if ($scope.filterVersion.length && $scope.filterVersion == 'B' && data.type.length) {
            data.types = [data.type];
            data.type = '';
        }

        if (data.priceTo) {
            data.priceTo = parseInt(data.priceTo);
        }

        if (data.priceFrom) {
            data.priceFrom = parseInt(data.priceFrom);
        }

        if (data.sizeFrom) {
            data.sizeFrom = parseInt(data.sizeFrom);
        }

        if (data.sizeTo) {
            data.sizeTo = parseInt(data.sizeTo);
        }

        $scope.filter = angular.extend($scope.filter, data);
        initialFilter = angular.copy($scope.filter);

        if (data.specie) {
            FilterProvider.loadCategories(data.specie)
                .then(function (categories) {
                    $scope.categories = categories;

                    const inputCategories = angular.isDefined($location.search()['category[]']) ? $location.search()['category[]'] : [];

                    if (inputCategories.length) {
                        $scope.categories.forEach(function (category) {
                            if (inputCategories.indexOf(category.value) !== -1) {
                                category.checked = true;
                            }
                        });

                        toggleCategory();
                    }
                });
        }

        if (data.locationText) {
            if (angular.isString(data.locationText)) {
                $scope.locationText = [];
                $scope.locationText.push(data.locationText);
            } else if (angular.isArray(data.locationText)) {
                $scope.locationText = angular.copy(data.locationText);
            }
            toggleLocationText();
        }

        if (data.kategoria) {
            if (angular.isString(data.kategoria)) {
                $scope.kategoria = [];
                $scope.kategoria.push(data.kategoria);
            } else if (angular.isArray(data.kategoria)) {
                $scope.kategoria = angular.copy(data.kategoria);
            }
            toggleKategoria();
        }

        if (data.types) {
            if (angular.isString(data.types)) {
                $scope.types2 = [];
                $scope.types2.push(data.types);
            } else if (angular.isArray(data.types)) {
                $scope.types2 = angular.copy(data.types);
            }
            toggleTypes();
        }

        FilterProvider.loadSpecies()
            .then(function (species) {
                $scope.species = species;

                if ($scope.filter.specie) {
                    $scope.species.forEach(function (item) {
                        item.checked = (item.value === $scope.filter.specie);
                    });
                }
            });

        FilterProvider.loadTypes()
            .then(function (types) {
                $scope.types = types;

                if ($scope.filter.type) {
                    $scope.types.forEach(function (item) {
                        item.checked = (item.value === $scope.filter.type);
                    });
                }
            });

        loadStates();

        if (data.country) {
            //loadRegions(data.country);
            //const regions = angular.isDefined($location.search()['region[]']) ? $location.search()['region[]'] : [];

            FilterProvider.loadRegions(data.country)
                .then(function (regions) {
                    $scope.regions = regions;

                    const inputRegions = angular.isDefined($location.search()['region[]']) ? $location.search()['region[]'] : [];

                    if (inputRegions.length) {
                        $scope.regions.forEach(function (region) {
                            if (inputRegions.indexOf(region.id) !== -1) {
                                region.checked = true;
                            }
                        });
                    }
                });

            toggleRegion();
        }

        $(document).ready(function () {
            // listener pre "enter press"
            $('#paramFilterSection').find('input').keypress(function (e) {
                if ((event.keyCode || event.which) == 13) {
                    filterProjects();
                }
            });
        });
    }

    function loadAmount() {
        $scope.loadingFilter = true;

        FilterProvider.loadAmount($scope.filter)
            .then(function (amount) {
                $scope.amount = amount;
            })
            .finally(function () {
                $scope.loadingFilter = false;
            });
    }

    function loadStates() {
        const promises = [];

        FilterProvider.loadStates()
            .then(function (states) {
                $scope.states = states;

                $scope.states.forEach(function (state) {
                    const promise = loadCountries(state.id).then(function (countries) {
                        state.countries = countries.map(function (country) {
                            country.placeholder = "<span class='country'>" + country.name + "</span>";
                            country.checked = $scope.filter.country === country.id;

                            return country;
                        });
                    });

                    promises.push(promise);
                });

                $q.all(promises).then(function () {
                    $scope.states.forEach(function (state) {
                        state.placeholder = "<span class='state'>" + state.name + "</span>";
                        state.checked = $scope.filter.state === state.id;
                        $scope.locations.push(state);
                        $scope.locations = $scope.locations.concat(state.countries);
                    });
                });
            });
    }

    function loadCountries(stateId) {
        const promise = FilterProvider.loadCountries(stateId)
            .then(function (countries) {
                $scope.countries = $scope.countries.concat(countries);

                return countries;
            });

        RealEstateSpinner.addPromise(promise);

        return promise;
    }

    function loadRegions(countryId) {
        const promise = FilterProvider.loadRegions(countryId)
            .then(function (regions) {
                $scope.regions = regions;

                const inputRegions = angular.isDefined($location.search()['region[]']) ? $location.search()['region[]'] : [];

                if (inputRegions.length) {
                    $scope.regions.forEach(function (region) {
                        if (inputRegions.indexOf(region.id) !== -1) {
                            region.checked = true;
                        }
                    });
                    toggleRegion();
                }
            });

        RealEstateSpinner.addPromise(promise);

        return promise;
    }

    function loadCities(regionsId) {
        const promise = FilterProvider.loadCities(regionsId)
            .then(function (cities) {
                $scope.cities = cities;

                const inputCities = angular.isDefined($location.search()['city[]']) ? $location.search()['city[]'] : [];

                if (inputCities.length) {
                    $scope.cities.forEach(function (city) {
                        if (inputCities.indexOf(city.id) !== -1) {
                            city.checked = true;
                        }
                    });

                    toggleCity();
                }
            });

        RealEstateSpinner.addPromise(promise);

        return promise;
    }

    function loadCategories(specie) {
        const promise = FilterProvider.loadCategories(specie)
            .then(function (categories) {
                $scope.categories = categories;
            });

        RealEstateSpinner.addPromise(promise);

        return promise;
    }

    function toggleCategory() {
        const selectedCategories = [];

        $scope.categories.forEach(function (category) {
            if (category.checked) {
                selectedCategories.push(category.value);
            }
        });

        $scope.filter.category = selectedCategories;
        if ($scope.filter.breadcrumbs === true && $scope.filter.specie.length && $scope.filter.category.length) {
            $scope.filter.kategoria = [$scope.filter.specie + ',' + $scope.filter.category[0]];
        }
    }

    function toggleLocationText() {
        $scope.filter.locationText = angular.copy($scope.locationText);
    }

    function toggleKategoria() {
        $scope.filter.kategoria = angular.copy($scope.kategoria);
    }

    function toggleTypes() {
        $scope.filter.types = angular.copy($scope.types2);
    }

    function toggleRegion() {
        const selectedRegions = [];

        $scope.regions.forEach(function (region) {
            if (region.checked) {
                selectedRegions.push(region.id);
            }
        });

        $scope.filter.region = selectedRegions;
    }

    function toggleCity() {
        const selectedRegions = [];

        $scope.cities.forEach(function (city) {
            if (city.checked) {
                selectedRegions.push(city.id);
            }
        });

        $scope.filter.city = selectedRegions;
    }

    function filterProjects(showMap) {

        const fitlerData = {};
        const displayMap = showMap || false;

        angular.forEach($scope.filter, function (param, key) {
            if (param) {
                fitlerData[key] = param;
            }
        });

        const queryString = $.param(fitlerData);
        const hash = displayMap ? '#map-filter' : '#result-filter';

        let href = projectsUrl + '?' + queryString;
        href = href + (queryString ? '&adverts' : 'adverts');

        if (angular.equals(initialFilter, $scope.filter)) {
            if (hash == '#map-filter') {
                $('#paramFilterSection').hide();
                $('#mapFilterSection').show();
                $('.adverts-list').hide();
                $('.tabNav').find('li').removeClass('active').eq(1).addClass('active');
            } else {
                $('.adverts-list').show();
            }
        }

        $window.location.href = href + hash;
    }


};